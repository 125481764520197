<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>Determinaciones</v-card-title>
        <ListDeterminations />
      </v-card>
  
    </div>
  </template>
  
  <script>
import ListDeterminations from '@/components/determinations/ListDeterminations.vue';



  export default {
   components: {
    ListDeterminations
},
   setup() {
      return {
        // icons
        icons: {
          
        },
      }
    },
  
  }
  </script>