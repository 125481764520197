import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"append-icon":_vm.icons.mdiMagnify,"placeholder":"Buscar por nombre...","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSpacer),_c(VSpacer),_c(VSelect,{staticClass:"mb-4",staticStyle:{"width":"0px"},attrs:{"items":_vm.specialCategories,"hide-details":"true","label":"Categoría","item-text":"name","item-value":"value","outlined":"","dense":""},on:{"change":_vm.setCategoryFilter},model:{value:(_vm.categoryFilter),callback:function ($$v) {_vm.categoryFilter=$$v},expression:"categoryFilter"}}),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.determinations,"search":_vm.search,"page":_vm.current_page,"server-items-length":_vm.totalListTable,"footer-props":{
      'items-per-page-options': [
      20, 
      50, 
      100 ],
      'items-per-page-text': 'Items por página',
      'page-text': '{0}-{1} de {2}'
    }},on:{"update:page":function($event){return _vm.changingDeterminationsPage($event)},"update:items-per-page":function($event){return _vm.changingItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("determinaciones")])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.readable))])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_c('span',[_vm._v(" $"+_vm._s(item.price))])])]}},{key:"item.special_category",fn:function(ref){
    var item = ref.item;
return [_c('span',{},[_vm._v(_vm._s(_vm._f("categoryTextFilter")(item.special_category.label)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c(VIcon,{staticClass:"me-2 ",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openPriceModal(item.id,item.price, item.readable, item.simulator)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilCircleOutline)+" ")])]}}],null,true)}),_c(VDialog,{attrs:{"width":"auto"},on:{"input":_vm.close},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Editar: "+_vm._s(_vm.editedName))]),_c(VCol,[_c(VTextField,{attrs:{"type":"number","outlined":"","dense":"","autocomplete":"off","rules":[_vm.rules.number],"hide-details":"auto","label":"Precio"},model:{value:(_vm.editedPrice),callback:function ($$v) {_vm.editedPrice=$$v},expression:"editedPrice"}})],1),_c(VCol,[_c(VCheckbox,{attrs:{"dense":"","label":"Aparece en simulador"},model:{value:(_vm.editedSimulator),callback:function ($$v) {_vm.editedSimulator=$$v},expression:"editedSimulator"}})],1),_c(VDivider),_c(VCardText,[_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.editedPrice < 0},on:{"click":_vm.editarPrecio}},[_vm._v(" Editar ")])],1)],1)],1),_c('alert-pop-up',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }