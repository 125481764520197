<template>
  <div>
    <v-divider class="mt-4"></v-divider>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
      v-model="search"
      :append-icon="icons.mdiMagnify"
      @keyup="handleSearch"
      placeholder="Buscar por nombre..."
      outlined
      hide-details
      dense
      class="user-search me-3 mb-4"
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>
        <v-select
          v-model="categoryFilter" 
          :items="specialCategories"
          style="width: 0px"
          class="mb-4"
          hide-details="true"
          label="Categoría"
          item-text="name"
          item-value="value"
          @change="setCategoryFilter"
          outlined
          dense
        ></v-select>
        <v-spacer></v-spacer>
    </v-card-text>
    
    <v-data-table
      :headers="headers"
      :items="determinations"
      :search="search"
      :page="current_page"
      :server-items-length="totalListTable"
      @update:page="changingDeterminationsPage($event)"
      @update:items-per-page="changingItemsPerPage($event)"
      :footer-props="{
        'items-per-page-options': [
        20, 
        50, 
        100,
        ],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }"
    >
    <!-- NO data -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No se encontraron
            <strong>determinaciones</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

    <!-- Nombre -->
      <template #[`item.name`]="{item}">
        <span class="font-weight-black"> {{ item.readable }}</span>
      </template>  

      <!-- Price -->
      <template #[`item.price`]="{item}">
        <span 
        class="font-weight-black"
        >
          <span> ${{ item.price }}</span>
        </span> 
      </template>
      
      <!-- special_category -->
      <template #[`item.special_category`]="{item}">
        <span class="">{{ item.special_category.label | categoryTextFilter }}</span>
      </template>  

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
          <v-icon
          class="me-2 "
          @click="openPriceModal(item.id,item.price, item.readable, item.simulator)"
          color="primary"
          >
          {{ icons.mdiPencilCircleOutline }}
          </v-icon>
      </template>

    </v-data-table>
    
    <!-- Edit Price Modal -->
    <v-dialog
    v-model="editModal"
    width="auto"
    @input="close"
    >
    <v-card>
        <v-card-title>Editar: {{ editedName }}</v-card-title>
        <v-col>
          <v-text-field
            type="number"
            v-model="editedPrice"
            outlined
            dense
            autocomplete="off"
            :rules="[rules.number]"
            hide-details="auto"
            label="Precio"
          />
        </v-col>
        <v-col>
          <v-checkbox
            dense 
            v-model="editedSimulator" 
            label="Aparece en simulador"
            ></v-checkbox>
        </v-col>
        <v-divider></v-divider>
        <v-card-text>
            <v-btn 
            text
            outlined
            color="primary"
            @click="close"
            class="mr-2"
            > 
              Cancelar
            </v-btn>
            <v-btn 
            color="primary"
            :disabled="editedPrice < 0"
            @click="editarPrecio"
            > 
                Editar
            </v-btn>
        </v-card-text>
    </v-card>
    </v-dialog>

    <alert-pop-up ref="alert_pop_up"/>

  </div>
</template>

<script>
import debounce from '../../plugins/debounce.js'
import AlertPopUp from '@/components/AlertPopUp.vue';
import { 
  mdiPencilCircleOutline,
  mdiMagnify, mdiCloseCircleOutline
} from '@mdi/js'

export default {
  data() {
    return {
      current_page: 1,
      page_size: 20,
      categoryFilter: -1,
      search: '',
      totalListTable: 0,
      determinations: [],
      selectedCategory: '',
      editModal: false,
      editedIndex: 0,
      editedPrice: null,
      editedSimulator: false,
      editedName: null,
      headers: [
        { text: 'NOMBRE', align: 'left', sortable: false, value: 'name'},
        { text: 'CATEGORIA', align: 'left',sortable: true, value: 'special_category'},
        { text: 'PRECIOS', align: 'left', sortable: true, value: 'price'},
        { text: 'EDITAR', align: 'center',sortable: true, value: 'actions'}
      ],
      rules: {
        number: value => (value >= 0 && value !== '')  || 'Ingrese un numero válido',
      },
    }
  },
  filters: {
    categoryTextFilter(value) {
      if (!value) return ''
      value = value.toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ')
    }
  },
  mounted () {
    this.getDeterminationsList()
  },
  methods: {
    handleSearch: debounce(function() {
      this.current_page = 1;
      this.getDeterminationsList();
    }, 400),
    changingDeterminationsPage(e) {
      this.current_page = e
      this.getDeterminationsList()
    },
    changingItemsPerPage(e) {
      this.page_size = e
      this.getDeterminationsList()
    },
    setCategoryFilter() {
      this.current_page = 1
      this.getDeterminationsList()
    },
    async getDeterminationsList(){
      const data = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.search,
        summary: false,
        special_category: this.categoryFilter
      }
      try {
        const response = await this.$api.getDeterminationsList(data) 
        this.determinations = response.results
        this.totalListTable = response.count
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async editarPrecio () {
      try {
      await this.$api.editDeterminationsList(this.editedIndex,{price: this.editedPrice, simulator: this.editedSimulator})
      this.getDeterminationsList()
      this.$refs.alert_pop_up.displayAlert({ message: 'Precio editado correctamente', type: 'success' })
    } catch(error) {
      this.$refs.alert_pop_up.displayAlert({ message: error.response.data.price, type: 'error' })
      console.log(error);
      }
      this.close()
    },
    openPriceModal (id,price,name, simulator) {
      this.editModal =  true
      this.editedPrice = price
      this.editedSimulator = simulator
      this.editedIndex = id
      this.editedName =  name
    },
    close () {
      this.editModal = false
      this.editedPrice = null
      this.editedSimulator = false
    },
  },
  setup(){
    const specialCategories = [
            { name: '-', value: -1 },
            // { name: 'principal', value: 0 },
            { name: 'Bioquímica sanguínea', value: 1 },
            { name: 'Endocrinología', value: 2 },
            { name: 'Hematología', value: 3 },
            { name: 'Ionograma', value: 4 },
            { name: 'Hemostasia - tiempos de coagulación', value: 5 },
            { name: 'Análisis de orina', value: 6 },
            { name: 'Examen de materia fecal', value: 7 },
            { name: 'Análisis dermatológicos', value: 8 },
            { name: 'Patología', value: 9 },
            { name: 'Líquidos de punción', value: 10 },
            { name: 'Plasma-suero', value: 11 },
            { name: 'Inmunodiagnóstico y serología', value: 12 },
            { name: 'Bacteriología', value: 13 },
            { name: 'Compatibilidad sanguínea', value: 14 },
            { name: 'Toxicología', value: 15 },
            { name: 'Biología molecular', value: 16 },
          ]

    return {
      specialCategories,
      icons:{
        mdiPencilCircleOutline,
        mdiMagnify,
        mdiCloseCircleOutline
      }
    }
  },
  components: {
    AlertPopUp
  }
}
</script>

<style lang="scss" scoped>
.v-icon:hover {
    transform: scale(1.2);
  }
</style>